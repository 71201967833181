import React from 'react';

import sectionStyles from './section-title.module.scss';

const SectionTitle: React.FC<{ inverted: boolean }> = ({
  inverted,
  children,
}) => (
  <div
    className={`${sectionStyles.sectionTitle} ${
      inverted ? sectionStyles.inverted : ''
    }`}
  >
    {children}
  </div>
);

export default SectionTitle;
