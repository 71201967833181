import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import SectionTitle from './section-title';
import { useStaticQuery, graphql } from 'gatsby';
import { Query } from '../graphql-types';
import { Mail, Phone, Smartphone } from 'react-feather';

import contactStyles from './contact.module.scss';

const Contact: React.FC = () => {
  const {
    strapiContact: { title, subtitle, phone, mobile, mail },
  } = useStaticQuery<Pick<Query, 'strapiContact'>>(graphql`
    {
      strapiContact {
        title
        subtitle
        phone
        mobile
        mail
      }
    }
  `);

  return (
    <div className={`${contactStyles.contactWrap} mt-5 pt-md-5`}>
      <Container>
        <div className="shadow-lg bg-primary text-light px-5 pb-md-5">
          <Row>
            <Col>
              <SectionTitle inverted>
                <span>{title}</span>
                {subtitle && <h2>{subtitle}</h2>}
              </SectionTitle>
            </Col>
          </Row>
          <div className="py-3 d-flex flex-wrap">
            {phone && (
              <div
                className="text-md-center text-light text-nowrap py-2 px-1"
                style={{ flexGrow: 1 }}
              >
                <a className="text-light" href={`tel:${phone}`}>
                  <Phone className="mr-1" />
                  {phone}
                </a>
              </div>
            )}
            {mobile && (
              <div
                className="text-md-center text-light text-nowrap py-2 px-1"
                style={{ flexGrow: 1 }}
              >
                <a className="text-light" href={`tel:${mobile}`}>
                  <Smartphone className="mr-1" />
                  {mobile}
                </a>
              </div>
            )}
            {mail && (
              <div
                className="text-md-center text-light text-nowrap py-2 px-1"
                style={{ flexGrow: 1 }}
              >
                <a className="text-light" href={`mailto:${mail}`}>
                  <Mail className="mr-1" />
                  {mail}
                </a>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
