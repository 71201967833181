import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import AreasOfLaw from '../components/areas-of-law';
import About from '../components/about';
import Contact from '../components/contact';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Hero />

    <main>
      <AreasOfLaw />
      <About />
      <Contact />
    </main>
  </Layout>
);

export default IndexPage;
