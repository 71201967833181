import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Query } from '../graphql-types';
import { Container, Row, Col } from 'reactstrap';
import ReactMarkdown from 'react-markdown';

import aboutStyles from './about.module.scss';
import SectionTitle from './section-title';

const About: React.FC = () => {
  const data = useStaticQuery<Pick<Query, 'strapiAbout'>>(graphql`
    {
      strapiAbout {
        title
        teaser
        subtitle
        slug
      }
    }
  `);

  const { title, teaser, subtitle, slug } = data.strapiAbout;

  return (
    <Container className={`mt-5 ${aboutStyles.about}`}>
      <Row>
        <Col lg="6" className={aboutStyles.image}></Col>
        <Col lg="6" className="bg-light pb-5 pl-lg-5">
          <SectionTitle>
            <span>{title}</span>
            <h2>{subtitle}</h2>
          </SectionTitle>
          <ReactMarkdown source={teaser} />
          <Link to={slug}>Mehr erfahren ></Link>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
