import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Award, ChevronRight } from 'react-feather';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Query, StrapiAreaOfLaw } from '../graphql-types';
import SectionTitle from './section-title';

const AOLCard: React.FC<StrapiAreaOfLaw> = ({ title, teaser, slug }) => (
  <Card style={{ flexGrow: 1 }}>
    <div className="corner-badge">
      <Award className="text-light ml-auto" width="28" height="28" />
    </div>

    <CardBody className="d-flex flex-column">
      <CardTitle>
        <h3 className="h6">{title}</h3>
      </CardTitle>

      <CardText style={{ fontSize: '0.8em', flexGrow: 1 }}>{teaser}</CardText>
      <Link to={slug}>
        Mehr <ChevronRight />
      </Link>
    </CardBody>
  </Card>
);

const AreasOfLaw: React.FC = () => {
  const data = useStaticQuery<
    Pick<Query, 'allStrapiAreaOfLaw' | 'allStrapiAreasOfLaw'>
  >(graphql`
    {
      allStrapiAreaOfLaw {
        nodes {
          slug
          teaser
          title
        }
      }

      allStrapiAreasOfLaw {
        nodes {
          title
          description
        }
      }
    }
  `);

  const areasOfLaw = data.allStrapiAreaOfLaw.nodes ?? [];

  const { title, description } = data.allStrapiAreasOfLaw.nodes[0];

  return (
    <Container>
      <Row>
        <Col>
          <SectionTitle>
            <span>{title}</span>
            {description && <h2>{description}</h2>}
          </SectionTitle>
        </Col>
      </Row>
      <Row>
        <div className="d-flex flex-wrap">
          {areasOfLaw.map((aol) => (
            <div
              key={aol.slug}
              className="m-2 d-flex flex-column"
              style={{ flexGrow: 1, width: '250px' }}
            >
              <AOLCard {...aol}></AOLCard>
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default AreasOfLaw;
